import React from "react";
import { LandingPageBannerFull } from "./LandingPageBannerFull";
import { LandingPageBannerHalf } from "./LandingPageBannerHalf";

// Make sure any changes here are reflected in studio/src/schemas/objects/landingPageBanner.ts
export enum LandingPageBannerStyle {
    Full = "Full",
    FullNoShadow = "FullNoShadow",
    Half = "Half",
    HalfNoShadow = "HalfNoShadow",
}

export const LandingPageBanner = (props) => {
    switch (props.bannerStyle) {
        case LandingPageBannerStyle.Full:
        case LandingPageBannerStyle.FullNoShadow:
            return <LandingPageBannerFull {...props} />;

        case LandingPageBannerStyle.Half:
        case LandingPageBannerStyle.HalfNoShadow:
        default:
            return <LandingPageBannerHalf {...props} />;
    }
};
