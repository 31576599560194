import ComposableBlocks from "../components/BlockContent/ComposableBlocks";
import { graphql } from "gatsby";
import React from "react";
import { Head } from "../components/Head";
import { usePageTheme } from "../styles/pageThemes";
import { ThemeProvider } from "styled-components";
import { RouteHeading } from "../components/RouteHeading";
import { gridSquares } from "../styles/grid";
import styled from "styled-components";
import { ContentGroups } from "../constants/contentGroups";
import { LandingPageBanner } from "../components/Banner/LandingPageBanner";

export default function LandingPage({ data }: GatsbyTypes.LandingPageQuery) {
    const { sanityLandingPage } = data;

    const {
        strapline = {},
        body = [],
        seoDetails,
        funnelStage,
        pageTheme,
        pageBannerObject,
    } = sanityLandingPage || {};

    const newTheme = usePageTheme(pageTheme);

    return (
        <ThemeProvider theme={newTheme}>
            <Head
                contentGroup={ContentGroups[funnelStage]}
                title={seoDetails?.title}
                description={seoDetails?.description}
                canonicalUrl={seoDetails?.canonicalUrl}
                image={seoDetails?.openGraphImage?.asset?.url}
                preventIndex={seoDetails?.preventIndex}
            />

            {strapline?.useBanner ? (
                <LandingPageBanner
                    title={strapline.title}
                    subtitle={strapline.subtitle}
                    bannerGraphic={pageBannerObject?.bannerGraphic}
                    mp4={pageBannerObject?.video?.mp4?.asset?.url}
                    webm={pageBannerObject?.video?.webm?.asset?.url}
                    image={seoDetails?.openGraphImage?.asset?.url}
                    ctaText={pageBannerObject?.ctaText}
                    ctaLink={pageBannerObject?.ctaLink}
                    bannerImage={pageBannerObject?.bannerImage?.asset?.url}
                    bannerStyle={pageBannerObject?.bannerStyle}
                    sectionThemeName={pageBannerObject?.sectionTheme}
                />
            ) : (
                <RouteHeading
                    title={strapline.title}
                    subtitle={strapline.subtitle}
                />
            )}

            <ComposableBlocks blocks={body} />
            <Spacer />
        </ThemeProvider>
    );
}

export const Spacer = styled.div`
    height: ${gridSquares(3.5)};
`;

export const pageQuery = graphql`
    query LandingPage($id: String!) {
        sanityLandingPage(id: { eq: $id }) {
            body {
                ... on SanityBlockList {
                    ...SanityBlockListFields
                }
                ... on SanityBlockTestimonial {
                    ...SanityBlockTestimonialFields
                }
                ... on SanityBlockVideo {
                    ...SanityBlockVideoFields
                }
                ... on SanityRelatedArticles {
                    ...SanityRelatedArticlesFields
                }
                ... on SanityPageBlockContent {
                    ...SanityPageBlockContentFields
                }
                ... on SanityBlockImageWithText {
                    ...SanityBlockImageWithTextFields
                }
                ... on SanityBlockTwoColumn {
                    ...SanityBlockTwoColumnFields
                }
                ... on SanityCarouselWithSideText {
                    ...SanityCarouselWithSideTextFields
                }
                ... on SanityVerticalPadding {
                    ...SanityVerticalPadding
                }
                ... on SanityJobBoard {
                    ...SanityJobBoard
                }
                ... on SanityBigMarkerRegistration {
                    ...SanityBigMarkerRegistration
                }
            }
            funnelStage
            pageUrl {
                current
            }
            seoDetails {
                canonicalUrl
                description
                openGraphImage {
                    asset {
                        url
                    }
                }
                title
                preventIndex
            }
            strapline {
                product
                subtitle
                title
                useBanner
            }
            pageTheme
            pageBannerObject {
                sectionTheme
                ctaText
                ctaLink
                bannerStyle
                bannerGraphic
                bannerImage {
                    asset {
                        url
                    }
                }
                video {
                    mp4 {
                        asset {
                            url
                        }
                    }
                    webm {
                        asset {
                            url
                        }
                    }
                }
            }
        }
    }
`;

export const query = graphql`
    fragment SanityBlockListFields on SanityBlockList {
        _type
        items
    }

    fragment SanityBlockTestimonialFields on SanityBlockTestimonial {
        _type
        testimonialsReferences {
            company
            name
            profile {
                img {
                    asset {
                        gatsbyImageData(layout: CONSTRAINED, width: 128)
                    }
                }
                alt
                link
                padding
                borderRadius
            }
            quote
        }
    }

    fragment SanityBlockVideoFields on SanityBlockVideo {
        _type
        title
        video {
            url
        }
    }

    fragment SanityRelatedArticlesFields on SanityRelatedArticles {
        _type
        relatedArticlesArray {
            ... on SanityBlogPost {
                description
                featuredImage {
                    img {
                        asset {
                            gatsbyImageData(layout: CONSTRAINED, width: 460)
                        }
                    }
                    alt
                }
                publishedAt(formatString: "MMMM YYYY")
                slug {
                    current
                }
                title
            }
        }
        relatedArticlesHeading
    }

    fragment SanityPageBlockContentFields on SanityPageBlockContent {
        _rawContent(resolveReferences: { maxDepth: 6 })
        _type
    }

    fragment SanityBlockImageWithTextFields on SanityBlockImageWithText {
        _type
        _rawSettings(resolveReferences: { maxDepth: 5 })
    }

    fragment SanityBlockTwoColumnFields on SanityBlockTwoColumn {
        _key
        _type
        twoColumnTitle
        bodyOne {
            ... on SanityBlockList {
                ...SanityBlockListFields
            }
            ... on SanityBlockTestimonial {
                ...SanityBlockTestimonialFields
            }
            ... on SanityBlockVideo {
                ...SanityBlockVideoFields
            }
            ... on SanityRelatedArticles {
                ...SanityRelatedArticlesFields
            }
            ... on SanityPageBlockContent {
                ...SanityPageBlockContentFields
            }
            ... on SanityBlockImageWithText {
                ...SanityBlockImageWithTextFields
            }
        }
        bodyTwo {
            ... on SanityBlockList {
                ...SanityBlockListFields
            }
            ... on SanityBlockTestimonial {
                ...SanityBlockTestimonialFields
            }
            ... on SanityBlockVideo {
                ...SanityBlockVideoFields
            }
            ... on SanityRelatedArticles {
                ...SanityRelatedArticlesFields
            }
            ... on SanityPageBlockContent {
                ...SanityPageBlockContentFields
            }
            ... on SanityBlockImageWithText {
                ...SanityBlockImageWithTextFields
            }
        }
    }

    fragment SanityCarouselWithSideTextFields on SanityCarouselWithSideText {
        _type
        carousel {
            display
            carouselItems {
                alt
                url
                image {
                    asset {
                        _id
                    }
                    hotspot {
                        height
                        width
                        x
                        y
                    }
                    crop {
                        bottom
                        left
                        right
                        top
                    }
                }
            }
        }
        ctaButton {
            linkUrl
            title
            variation
        }
        heading
        description
        carouselPosition
    }

    fragment SanityVerticalPadding on SanityVerticalPadding {
        _type
        size
    }

    fragment SanityJobBoard on SanityJobBoard {
        _type
        cardThemeVariation
    }

    fragment SanityBigMarkerRegistration on SanityBigMarkerRegistration {
        _type
        webinarId
        title
        button
        formWidth
        formHeight
    }
`;
