import React from "react";
import { Heading } from "../Typography/Heading";

import styled, { ThemeProvider } from "styled-components";
import Button from "../Button";

import { gridSquares } from "../../styles/grid";
import { GridGutterRows } from "../GridGutters";
import { useSectionTheme } from "../../styles/pageThemes";
import { LandingPageBannerStyle } from "./LandingPageBanner";

export const LandingPageBannerHalf = ({
    title,
    subtitle,
    ctaText,
    ctaLink,
    bannerImage,
    bannerStyle,
    sectionThemeName,
    bannerGraphic,
    mp4,
    webm,
}) => {
    const hasContent = Boolean(title || subtitle || ctaText);
    const useMask = bannerStyle === LandingPageBannerStyle.Half;
    const sectionTheme = useSectionTheme(sectionThemeName);
    const hasBannerGraphic = Boolean(bannerGraphic || bannerImage);

    // If video is set and exists, use that. For existing pages, fall back to image as bannerGraphic is not set
    const getBannerGraphic = () => {
        if (bannerGraphic === "video" && mp4) {
            return (
                <FeaturedVideo
                    autoPlay
                    loop
                    muted
                    preload="auto"
                    disablePictureInPicture
                >
                    {webm && <source src={webm} type="video/webm" />}
                    <source src={mp4} type="video/mp4" />
                </FeaturedVideo>
            );
        } else if (bannerImage) {
            return <FeaturedImage src={bannerImage} alt="" aria-hidden />;
        }
    };

    return (
        <ThemeProvider theme={sectionTheme}>
            <LayoutContainer
                $hasContent={hasContent}
                $hasBottomPadding={
                    bannerStyle !== LandingPageBannerStyle.HalfNoShadow
                }
            >
                {hasContent && (
                    <Content>
                        {title && <Heading level="h1">{title}</Heading>}
                        {subtitle && <Subtitle>{subtitle}</Subtitle>}
                        {ctaText && (
                            <>
                                <GridGutterRows gutters={1} />
                                <Button href={ctaLink}>{ctaText}</Button>
                            </>
                        )}
                    </Content>
                )}
                {hasBannerGraphic && (
                    <ImageLayout>
                        {getBannerGraphic()}
                        {useMask && (
                            <>
                                <FullWidthMask />
                                <HalfWidthMask />
                            </>
                        )}
                    </ImageLayout>
                )}
            </LayoutContainer>
        </ThemeProvider>
    );
};

const Subtitle = styled.div`
    margin-bottom: 16px;
`;

const LayoutContainer = styled.div<{
    $hasContent: boolean;
    $hasBottomPadding: boolean;
}>`
    position: relative;
    overflow-x: clip;
    background-color: ${(p) => p.theme.sectionTheme.backgroundColor};
    color: ${(p) => p.theme.sectionTheme.fontColor};
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
        "image"
        "ctaContent";
    width: 100vw;
    margin-bottom: ${gridSquares(4)};
    padding-bottom: ${gridSquares(4)};
    padding-bottom: ${(p) => (p.$hasBottomPadding ? gridSquares(4) : "0px")};
    gap: ${gridSquares(3)};

    ${(p) => p.theme.media("md")} {
        padding-left: 4vw;
        grid-template-areas: "ctaContent image";
        grid-template-rows: auto;
        grid-template-columns: 3fr 7fr;
    }
    ${(p) => p.theme.media("lg")} {
        padding-left: 5vw;
        grid-template-columns: 4fr 6fr;
    }
`;

const Content = styled.div`
    padding: ${gridSquares(1)} ${gridSquares(1)} ${gridSquares(0)};
    grid-area: ctaContent;
    display: flex;
    flex-direction: column;
    align-items: start;
    z-index: 2;
    justify-content: center;
`;

const FeaturedImage = styled.img`
    height: 100%;
    width: 100%;
`;

const FeaturedVideo = styled.video`
    width: 100%;
    height: 100%;
`;

const ImageLayout = styled.div`
    width: 100%;
    grid-area: image;
    position: relative;

    aspect-ratio: 16/9;

    .imageMask {
        z-index: 1;
        position: absolute;
    }

    .halfMask {
        display: none;
        margin: -1%;
    }
    .mask-fill,
    .mask-fill-full {
        fill: ${(p) => p.theme.sectionTheme.backgroundColor};
    }
    .mask-shadow {
        fill: ${(p) => p.theme.color.primary.main};
        opacity: 0.5;
    }
    .fullMask {
        display: block;
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: -1px;
    }

    ${(p) => p.theme.media("md")} {
        .halfMask {
            display: block;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
        }
        .fullMask {
            display: none;
        }
    }
`;

const HalfWidthMask = () => (
    <svg
        className="imageMask halfMask"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1920 1080"
    >
        <path
            className="mask-shadow"
            d="M1795.7,835.1c-54.6-26.5-117.4,15.9-113.2,76.5l2.7,38.8c-2.3-0.4-4.7-0.8-7.1-1l-696-55.9
	c-36.4-2.9-68.6,23.5-73,59.5c-61.1-50.9-139.5-81.4-225.1-81.4c-94.5,0-180.4,37.2-243.7,97.9l-6.6-65.3
	c-3.1-29.9-29.8-51.8-59.8-48.7l-192.2,19.6L246.1,689c22.9-66.4-24-132.6-88.7-138.8c35.1-59.9,55.3-129.5,55.3-203.9
	s-20.4-144.9-56-205l59.9-48c30.7-24.6,23.2-73.3-13.5-87.5L188.4,0H-2v1065.6h1308.5l0,0H1920V899.8L1795.7,835.1z"
        />
        <path
            className="mask-fill"
            d="M1893.6,894.7c-54.6-26.5-117.4,15.9-113.2,76.5l4.3,62.9c-4.6-1.3-9.4-2.1-14.3-2.5l-781.1-62.7
	c-40.9-3.3-76.9,26.3-81.9,66.8c-68.5-57.1-156.6-91.4-252.7-91.4c-106.1,0-202.4,41.8-273.4,109.9l-7.5-73.3
	c-3.4-33.6-33.4-58.1-67.1-54.7L91,948.3l72.3-209c25.7-74.4-26.9-148.8-99.5-155.8c39.4-67.2,62.1-145.3,62.1-228.8
	s-22.9-162.7-62.8-230l67.1-54c22.6-18.1,26.7-47.8,15.3-70.7H-1v1079.2h1788.7l0,0H1921V908L1893.6,894.7z"
        />
    </svg>
);

const FullWidthMask = () => {
    return (
        <svg
            className="imageMask
            fullMask"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1757 224.27"
        >
            <g className="cls-3">
                <g id="Layer_2">
                    <g id="Layer_1-2">
                        <path
                            className="mask-shadow-full"
                            fill="#306"
                            opacity=".5"
                            d="m1628.34,74.31c-32.97,10.05-51.13,40.45-49.72,70.74l-72.76-36.39c-16.78-8.39-37.19-1.59-45.59,15.19l-24.92,49.82c-49.49-48.71-116.45-80-191.27-83.32-68.81-3.06-133.09,18.03-184.68,55.75l-133.71-47.15c-23.13-8.16-48.49,3.98-56.65,27.11l-11.23,31.85-.96-15.47c-1.16-18.73-17.29-32.97-36.01-31.81l-141.61,8.79c-16.8,1.04-29.98,14.13-31.68,30.33-22.72-37.86-63.39-63.97-110.8-66.08-45.98-2.04-87.65,18.96-113.84,52.79l-26.16-36.28c-31.65-43.9-98.97-37.02-121.09,12.37l-13.96,31.18C196.67,67.6,104.84,14.68,0,0v210.61h1757V35.1l-128.66,39.22Z"
                        />
                        <path
                            className="mask-fill-full"
                            fill="#fff"
                            d="m1628.34,109.97c-32.97,10.05-51.13,40.45-49.72,70.74l-72.76-36.39c-16.78-8.39-37.19-1.59-45.59,15.19l-24.92,49.82c-49.49-48.71-116.45-80-191.27-83.32-68.81-3.06-133.09,18.03-184.68,55.75l-133.71-47.15c-23.13-8.16-48.49,3.98-56.65,27.11l-11.23,31.85-.96-15.47c-1.16-18.73-17.29-32.97-36.01-31.81l-141.61,8.79c-16.8,1.04-29.98,14.13-31.68,30.33-22.72-37.86-63.39-63.97-110.8-66.08-45.98-2.04-87.65,18.96-113.84,52.79l-26.16-36.28c-31.65-43.9-98.97-37.02-121.09,12.37l-13.96,31.18C196.67,103.27,104.84,50.34,0,35.66v188.61h1757V70.76l-128.66,39.22Z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
