import React from "react";
import { Heading } from "../Typography/Heading";

import styled, { ThemeProvider } from "styled-components";
import Button from "../Button";

import { gridSquares } from "../../styles/grid";
import { GridGutterRows } from "../GridGutters";
import { useSectionTheme } from "../../styles/pageThemes";
import { LandingPageBannerStyle } from "./LandingPageBanner";

export const LandingPageBannerFull = ({
    title,
    subtitle,
    ctaText,
    ctaLink,
    bannerImage,
    bannerStyle,
    sectionThemeName,
    bannerGraphic,
    mp4,
    webm,
}) => {
    const hasContent = Boolean(title || subtitle || ctaText);
    const useMask = bannerStyle === LandingPageBannerStyle.Full;
    const sectionTheme = useSectionTheme(sectionThemeName);
    const hasBannerGraphic = Boolean(bannerGraphic || bannerImage);

    // If video is set and exists, use that. For existing pages, fall back to image as bannerGraphic is not set
    const getBannerGraphic = () => {
        if (bannerGraphic === "video" && mp4) {
            return (
                <FeaturedVideo
                    autoPlay
                    loop
                    muted
                    preload="auto"
                    disablePictureInPicture
                >
                    {webm && <source src={webm} type="video/webm" />}
                    <source src={mp4} type="video/mp4" />
                </FeaturedVideo>
            );
        } else if (bannerImage) {
            return <FeaturedImage src={bannerImage} alt="" aria-hidden />;
        }
    };

    return (
        <ThemeProvider theme={sectionTheme}>
            <LayoutContainer $hasContent={hasContent}>
                {hasContent && (
                    <Content>
                        {title && <Heading level="h1">{title}</Heading>}
                        {subtitle && <Subtitle>{subtitle}</Subtitle>}
                        {ctaText && (
                            <>
                                <GridGutterRows gutters={1} />
                                <Button href={ctaLink}>{ctaText}</Button>
                            </>
                        )}
                    </Content>
                )}
                {hasBannerGraphic && (
                    <ImageLayout>
                        {getBannerGraphic()}
                        {useMask && <FullWidthMask />}
                    </ImageLayout>
                )}
            </LayoutContainer>
        </ThemeProvider>
    );
};

const Subtitle = styled.div`
    margin-bottom: 16px;
`;

const LayoutContainer = styled.div<{ $hasContent: boolean }>`
    position: relative;
    overflow-x: clip;
    background-color: ${(p) => p.theme.sectionTheme.backgroundColor};
    color: ${(p) => p.theme.sectionTheme.fontColor};
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
        "image"
        "ctaContent";
    width: 100vw;
    margin-bottom: ${gridSquares(4)};
    gap: ${gridSquares(3)};
`;

const Content = styled.div`
    padding: 0px ${gridSquares(1)} ${gridSquares(4)};
    grid-area: ctaContent;
    display: flex;
    flex-direction: column;
    align-items: start;
    z-index: 2;

    ${(p) => p.theme.media("sm")} {
        align-items: center;
    }
`;

const FeaturedImage = styled.img`
    object-fit: cover;
    width: 100%;
`;

const FeaturedVideo = styled.video`
    width: 100%;
`;

const ImageLayout = styled.div`
    width: 100%;
    grid-area: image;
    position: relative;

    position: relative;
    max-height: 500px;
    overflow: clip;

    @media (min-height: 800px) and (min-width: 1920px) {
        max-height: 800px;
    }

    .imageMask {
        z-index: 1;
        position: absolute;
    }

    .mask-fill {
        fill: ${(p) => p.theme.sectionTheme.backgroundColor};
    }
    .mask-shadow {
        fill: ${(p) => p.theme.color.primary.main};
        opacity: 0.5;
    }
    .fullMask {
        display: block;
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: -1px;
    }
`;

const FullWidthMask = () => {
    return (
        <svg
            className="imageMask
            fullMask"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1757 224.27"
        >
            <g className="cls-3">
                <g id="Layer_2">
                    <g id="Layer_1-2">
                        <path
                            className="mask-shadow"
                            d="m1628.34,74.31c-32.97,10.05-51.13,40.45-49.72,70.74l-72.76-36.39c-16.78-8.39-37.19-1.59-45.59,15.19l-24.92,49.82c-49.49-48.71-116.45-80-191.27-83.32-68.81-3.06-133.09,18.03-184.68,55.75l-133.71-47.15c-23.13-8.16-48.49,3.98-56.65,27.11l-11.23,31.85-.96-15.47c-1.16-18.73-17.29-32.97-36.01-31.81l-141.61,8.79c-16.8,1.04-29.98,14.13-31.68,30.33-22.72-37.86-63.39-63.97-110.8-66.08-45.98-2.04-87.65,18.96-113.84,52.79l-26.16-36.28c-31.65-43.9-98.97-37.02-121.09,12.37l-13.96,31.18C196.67,67.6,104.84,14.68,0,0v210.61h1757V35.1l-128.66,39.22Z"
                        />
                        <path
                            className="mask-fill"
                            d="m1628.34,109.97c-32.97,10.05-51.13,40.45-49.72,70.74l-72.76-36.39c-16.78-8.39-37.19-1.59-45.59,15.19l-24.92,49.82c-49.49-48.71-116.45-80-191.27-83.32-68.81-3.06-133.09,18.03-184.68,55.75l-133.71-47.15c-23.13-8.16-48.49,3.98-56.65,27.11l-11.23,31.85-.96-15.47c-1.16-18.73-17.29-32.97-36.01-31.81l-141.61,8.79c-16.8,1.04-29.98,14.13-31.68,30.33-22.72-37.86-63.39-63.97-110.8-66.08-45.98-2.04-87.65,18.96-113.84,52.79l-26.16-36.28c-31.65-43.9-98.97-37.02-121.09,12.37l-13.96,31.18C196.67,103.27,104.84,50.34,0,35.66v188.61h1757V70.76l-128.66,39.22Z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
